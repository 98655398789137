<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="20"
    >
      <!-- <base-icon class="mb-8">
        mdi-instagram
      </base-icon> -->

      <base-section-heading
        color="transparent"
        title="Social Media"
      >
        Follow us in the social media platform to know about our work and how our clients benefit from our engagement.
      </base-section-heading>
      <!-- Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi aspernatur recusandae aut repudiandae illo error obcaecati dolores voluptate, tempore.
        Follow us in the social media platform to know about our work and how our clients benefit from our engagement.
      </base-section-heading>-->

      <!-- <a
        class="d-inline-block mb-8"
        href="https://www.hi5magic.com"
        style="text-decoration: none;"
        target="_blank"
      >
        https://vuetifyjs.com
        https://www.hi5magic.com
      </a> -->

      <!-- <div class="py-4" /> -->

      <!-- <v-btn
        class="font-weight-bold"
        color="white"
        href="https://twitter.com/vuetifyjs"
        light
        min-width="168"
        target="_blank"
        x-large
      > -->
      <v-container>
        <div class="d-flex flex-wrap justify-md-center justify-center justify-md-none">
          <template v-for="({ logo, text,title}, n) in media">
            <a
              :key="n"
              :href="text"
              style="text-decoration: none;"
            >
              <base-icon
                color="white"
              >
                {{ `mdi-${logo}` }}
              </base-icon>
            </a>
            <v-responsive
              v-if="n < media.length - 1"
              :key="`divider-${title}`"
              class="mx-2 shrink hidden-sm-and-down"
              max-height="56"
              width="48"
            >
              <v-divider vertical />
            </v-responsive>
          </template>
        </div>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
    data: () => ({
      media: [
        {
          logo: 'facebook',
          title: 'Facebook',
          colour: 'indigo',
          text: 'https://www.facebook.com/hi5magic',
        },
        {
          logo: 'instagram',
          title: 'Instagram',
          colour: 'pink darken-1',
          text: 'https://www.instagram.com/hi5magic',
        },
        {
          logo: 'linkedin',
          title: 'LinkedIn',
          colour: 'pink darken-1',
          text: 'https://in.linkedin.com/in/hi5magic',
        },
      ],
    }),
  }
</script>
